import {useState} from "#app";

export function askForConfirmPopup() {

    const confirmMMessage = useState('confirm-message', () => null);

    const askForConfirm = (data) => {
        confirmMMessage.value = data;
    }

    return {askForConfirm, confirmMessage: confirmMMessage}
}
